import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
import SaveFieldBtn from '@/components/SaveField.vue';
let Patronym = class Patronym extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.rules = [];
        this.oldValue = '';
    }
    mounted() {
        this.rules.push(this.inputRules.required);
        this.oldValue = this.patronym;
    }
    get iconName() {
        return this.checkError(this.$refs.patronym) ? 'close-icon' : this.patronym ? 'check-icon' : null;
    }
    get patronym() {
        return this.$store.state.personalArea.patient.patronymic;
    }
    set patronym(value) {
        this.$store.commit('personalArea/setPatientProperty', {
            name: 'patronymic',
            value: this.checkInputValueByRegExp(this.onlyLetters, value),
        });
    }
    async saveData() {
        if (!this.patronym)
            return;
        await this.$store.dispatch('personalArea/updatePatientData', { patronymic: this.patronym });
        this.oldValue = this.patronym;
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], Patronym.prototype, "disableAcceptField", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], Patronym.prototype, "disableField", void 0);
Patronym = __decorate([
    Component({
        components: {
            SaveFieldBtn,
        },
    })
], Patronym);
export default Patronym;
