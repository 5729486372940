import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
let SaveFieldBtn = class SaveFieldBtn extends Vue {
    constructor() {
        super(...arguments);
        this.showField = false;
        this.saved = false;
    }
    save() {
        return true;
    }
    saveField() {
        this.save();
        this.saved = true;
    }
    onShowChange() {
        if (!this.show) {
            setTimeout(() => {
                this.showField = this.show;
                this.saved = false;
            }, 2000);
            return;
        }
        this.showField = this.show;
    }
};
__decorate([
    Prop()
], SaveFieldBtn.prototype, "onSave", void 0);
__decorate([
    Prop()
], SaveFieldBtn.prototype, "disable", void 0);
__decorate([
    Prop({ default: 'right' })
], SaveFieldBtn.prototype, "position", void 0);
__decorate([
    Prop({ default: '#63C58A' })
], SaveFieldBtn.prototype, "color", void 0);
__decorate([
    Prop({ default: false })
], SaveFieldBtn.prototype, "show", void 0);
__decorate([
    Emit('save')
], SaveFieldBtn.prototype, "save", null);
__decorate([
    Watch('show')
], SaveFieldBtn.prototype, "onShowChange", null);
SaveFieldBtn = __decorate([
    Component({})
], SaveFieldBtn);
export default SaveFieldBtn;
